<template>
  <ul v-if="user" class="sidebar-panel nav" :class="{'closed': !opened && !hovered}" @mouseenter="mouseEnterCollapse"
      @mouseleave="mouseLeaveCollapse">
    <li class="hamburger">
      <div title="Collapse Menu" @click="toggleSidebar"><i class="fas fa-bars"></i></div>
    </li>

    <li v-if="user.permissions.dashboard">
      <router-link :to="{name: 'Home'}" exact active-class="active">
        <i class="fas fa-home"/>
        <span class="nav-label">Dashboard</span>
      </router-link>
    </li>

    <li v-if="user.permissions.admin_pages">
      <router-link :to="{name: 'AdminAccess'}" active-class="active">
        <i class="fa fa-user-circle"/>
        <span class="nav-label">Admin Access</span>
      </router-link>
    </li>

    <li v-if="user.permissions.leads_handlers">
      <router-link :to="{name: 'LeadsHandlersAccessPage'}" active-class="active">
        <i class="fa fa-user-circle"/>
        <span class="nav-label">Leads Handlers</span>
      </router-link>
    </li>

    <li v-if="user.permissions.leads">
      <router-link :to="{name: 'Leads'}" exact active-class="active">
        <i class="fa fa-tag"/>
        <span class="nav-label">Leads</span>
      </router-link>
    </li>

    <li v-if="user.permissions.leadsRelated">
      <router-link :to="{name: 'LeadsRelated'}" active-class="active">
        <i class="fa fa-tag"/>
        <span class="nav-label">Related Leads</span>
      </router-link>
    </li>

    <li v-if="user.permissions.archivedleads">
      <router-link :to="{name: 'LeadsArchive'}" active-class="active">
        <i class="fa fa-archive"/>
        <span class="nav-label">Leads Archive</span>
      </router-link>
    </li>

    <li v-if="user.permissions.leadChart">
      <router-link :to="{name: 'LeadsTable'}" active-class="active">
        <i class="fas fa-table" />
        <span class="nav-label">Leads Table</span>
      </router-link>
    </li>

    <li v-if="user.permissions.leadChart">
      <router-link :to="{name: 'LeadsChart'}" active-class="active">
        <i class="fa fa-chart-area"/>
        <span class="nav-label">Leads Chart</span>
      </router-link>
    </li>

    <li v-if="user.permissions.campaigns">
      <router-link :to="{name: 'Campaigns'}" exact active-class="active">
        <i class="fa fa-flag"/>
        <span class="nav-label">Campaigns</span>
      </router-link>
    </li>

    <li v-if="user.permissions.changelog">
      <router-link :to="{name: 'CampaignsChangelog'}" exact active-class="active">
        <i class="fa fa-list-alt"/>
        <span class="nav-label">Changelog</span>
      </router-link>
    </li>

    <li v-if="user.permissions.activityLog">
      <router-link :to="{name: 'ActivityLog'}" active-class="active">
        <i class="fa fa-list-alt"/>
        <span class="nav-label">Activity Log</span>
      </router-link>
    </li>

    <li v-if="user.permissions.campaignCategories">
      <router-link :to="{name: 'CampaignCategories'}" active-class="active">
        <i class="fa fa-folder"/>
        <span class="nav-label">Campaign Categories</span>
      </router-link>
    </li>

    <li v-if="user.permissions.campaignMap">
      <router-link :to="{name: 'CampaignGmbsMap'}" active-class="active">
        <i class="fa fa-map-marker"/>
        <span class="nav-label">Campaign Map</span>
      </router-link>
    </li>

    <li v-if="user.permissions.campaignGmbs">
      <router-link :to="{name: 'CampaignGmbs'}" exact active-class="active">
        <i class="fa fa-folder"/>
        <span class="nav-label">GMBs</span>
      </router-link>
    </li>

    <li v-if="user.permissions.googleLocations">
      <router-link :to="{name: 'GMBManager'}" exact active-class="active">
        <i class="fa fa-map-marker"/>
        <span class="nav-label">GMB Manager</span>
      </router-link>
    </li>

    <li v-if="user.permissions.clients">
      <router-link :to="{name: 'Clients'}" exact active-class="active">
        <i class="fa fa-user"/>
        <span class="nav-label">Clients</span>
      </router-link>
    </li>

    <li v-if="user.permissions.clientSites">
      <router-link :to="{name: 'ClientSites'}" active-class="active">
        <i class="fa fa-file-alt"/>
        <span class="nav-label">Client Sites</span>
      </router-link>
    </li>

    <li v-if="user.permissions.networkSites">
      <router-link :to="{name: 'NetworkSites'}" exact active-class="active">
        <i class="fa fa-sitemap"/>
        <span class="nav-label">Network Sites</span>
      </router-link>
    </li>

    <li v-if="user.permissions.outgoingLinks">
      <router-link :to="{name: 'OutgoingLinks'}" active-class="active">
        <i class="fa fa-sitemap"/>
        <span class="nav-label">Links</span>
      </router-link>
    </li>

    <li v-if="user.permissions.networkCategories">
      <router-link :to="{name: 'Categories'}" active-class="active">
        <i class="fa fa-folder"/>
        <span class="nav-label">Network Categories</span>
      </router-link>
    </li>

    <li v-if="user.permissions.redirectDomains">
      <router-link :to="{name: 'RedirectDomains'}" active-class="active">
        <i class="fa fa-redo"/>
        <span class="nav-label">301 Domains List</span>
      </router-link>
    </li>

    <li v-if="user.permissions.ipAddresses">
      <router-link :to="{name: 'Ips'}" active-class="active">
        <i class="fa fa-hashtag"/>
        <span class="nav-label">IP Addresses</span>
      </router-link>
    </li>

    <li>
      <a href="https://www.markmywordsmedia.com/lead-manager/" target="_blank">
        <i class="fa fa-question"/>
        <span class="nav-label">User Guide</span>
      </a>
    </li>
    <li>
      <a href="https://www.markmywordsmedia.com/resources/" target="_blank">
        <i class="fa fa-file"/>
        <span class="nav-label">Resources</span>
      </a>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SideNav',
  data: function () {
    return {
      opened: false,
      hovered: false,
    }
  },
  methods: {
    mouseEnterCollapse() {
      this.hovered = true
    },
    mouseLeaveCollapse() {
      this.hovered = false
    },
    toggleSidebar() {
      this.opened = !this.opened
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  }
}
</script>

<style lang="scss" scoped>
@import "~/src/assets/css/_variables";

.sidebar-panel {

  i {
    width: 28px;
    text-align: center;
    margin-right: 6px;
  }

  &.closed {
    width: 30px;

    .nav-label {
      display: none;
    }

    .label-old {
      display: none;
    }
  }
}

.label-old {
  color: #e38237;
  padding-left: 7px;
  display: inline-block;
}

.hamburger {
  margin-bottom: 7px;
  font-size: 20px;
  cursor: pointer;
}

</style>
