import api from '@/utils/api'
import { Message } from 'element-ui'

export default {
	state: () => ({
		admins: null,
		adminsWithSuperAdmin: null,
		pagesList: null
	}),
	mutations: {
		setAdmins(state, payload) {
			state.admins = Object.freeze(Object.values(payload))
		},

		setAdminsWithSuperAdmin(state, payload) {
			state.adminsWithSuperAdmin = Object.values(payload)
		},

		setPagesList(state, payload) {
			state.pagesList = payload
		}
	},
	getters: {},
	actions: {
		async fetchAdmins({ commit, state }, cache = true) {
			if (cache && state.admins) return
			const result = await api.http.get('admin/admins')
			commit('setAdmins', result?.data?.admins)
		},

		async fetchAdminsWithSuperAdmin({ commit }) {
			const result = await api.http.get('admin/admins', {
				params: {
					with_super_admin: true
				}
			})
			commit('setAdminsWithSuperAdmin', result?.data?.admins)
		},

		async fetchPagesList({ commit }) {
			const result = await api.http.get('admin/pages-list')
			commit('setPagesList', result?.data?.pages)
		},

		async createAdmin({ dispatch }, form) {
			await api.http.post('admin/admins', form)
			dispatch('fetchAdmins', false)
			Message.success('Admin was created successfully')
		},

		async updateAdmin({ dispatch }, form) {
			await api.http.post(`admin/admins/${form.id}`, form)
			dispatch('fetchAdmins', false)
			Message.success('Admin was changed successfully')
		},

		async fetchAdminById(context, adminId) {
			const res = await api.http.get(`admin/admins/${adminId}`)
			return res?.data
		},

		async deleteAdmin({ dispatch }, id) {
			await api.http.delete(`admin/admins/${id}`)
			dispatch('fetchAdmins', false)
			Message.success('Admin was deleted successfully')
		},

    async moveToLeadsHandlers({ dispatch }, id) {
      await api.http.patch(`admin/admins/${id}/move-to-lead-handlers`)
      dispatch('fetchAdmins', false)
      Message.success('Success')
    }
	},
	namespaced: true
}
