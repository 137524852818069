var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form", attrs: { model: _vm.filters, "label-position": "top" } },
    [
      _c(
        "div",
        { staticClass: "filter-form" },
        [
          !_vm.isExtraFiltersHidden
            ? _c(
                "div",
                {
                  staticClass: "collapse-button",
                  on: { click: _vm.toggleFilters }
                },
                [
                  !_vm.isExtraFilersCollapsed
                    ? _c("i", { staticClass: "fas fa-chevron-up" })
                    : _vm._e(),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isExtraFilersCollapsed ? "more" : "less") +
                        " "
                    )
                  ]),
                  _c("div", [_vm._v(" filters ")]),
                  _vm.isExtraFilersCollapsed
                    ? _c("i", { staticClass: "fas fa-chevron-down" })
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "Date Range" } },
            [
              _c("el-date-picker", {
                staticClass: "date-range-picker",
                attrs: {
                  clearable: false,
                  "end-placeholder": "End date",
                  format: "MM/dd/yyyy",
                  "range-separator": "To",
                  "start-placeholder": "Start date",
                  type: "daterange",
                  "value-format": "yyyy-MM-dd"
                },
                on: { change: _vm.doFilter },
                model: {
                  value: _vm.filters.dateRange,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "dateRange", $$v)
                  },
                  expression: "filters.dateRange"
                }
              })
            ],
            1
          ),
          _vm.isAdminOrSuperAdmin
            ? _c(
                "el-form-item",
                { attrs: { label: "Category", prop: "category_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "multiselect",
                      on: { change: _vm.doFilter },
                      model: {
                        value: _vm.filters.category_id,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "category_id", $$v)
                        },
                        expression: "filters.category_id"
                      }
                    },
                    _vm._l(_vm.campaignCategories, function(category) {
                      return _c("el-option", {
                        key: category.id,
                        attrs: { label: category.name, value: category.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "Campaigns" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "multiselect-big",
                  attrs: { filterable: "", multiple: "" },
                  on: { change: _vm.doFilter },
                  model: {
                    value: _vm.filters.campaign_ids,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "campaign_ids", $$v)
                    },
                    expression: "filters.campaign_ids"
                  }
                },
                _vm._l(_vm.allNonArchivedCampaigns, function(campaign) {
                  return _c("el-option", {
                    key: campaign.id,
                    attrs: { label: campaign.name, value: campaign.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Tags" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "multiselect",
                  attrs: { filterable: "", multiple: "" },
                  on: { change: _vm.doFilter },
                  model: {
                    value: _vm.filters.tags,
                    callback: function($$v) {
                      _vm.$set(_vm.filters, "tags", $$v)
                    },
                    expression: "filters.tags"
                  }
                },
                _vm._l(_vm.tagsList, function(tag) {
                  return _c("el-option", {
                    key: tag,
                    attrs: { label: tag, value: tag }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Search" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "debounce",
                    rawName: "v-debounce",
                    value: _vm.doFilter,
                    expression: "doFilter"
                  }
                ],
                staticClass: "search-input",
                model: {
                  value: _vm.filters.lead_search,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "lead_search", $$v)
                  },
                  expression: "filters.lead_search"
                }
              })
            ],
            1
          ),
          _vm.isFiltersChanged
            ? _c(
                "el-button",
                {
                  staticClass: "reset-button",
                  attrs: { type: "gray" },
                  on: { click: _vm.clearFilters }
                },
                [_vm._v(" Reset ")]
              )
            : _vm._e()
        ],
        1
      ),
      !_vm.isExtraFilersCollapsed
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "filters-wrapper"
            },
            [
              !_vm.isExtraFiltersHidden
                ? _c(
                    "div",
                    { staticClass: "radio-buttons-wrapper" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          on: { change: _vm.doFilter },
                          model: {
                            value: _vm.filters.lead_type,
                            callback: function($$v) {
                              _vm.$set(_vm.filters, "lead_type", $$v)
                            },
                            expression: "filters.lead_type"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: null } }, [
                            _vm._v("All")
                          ]),
                          _vm._l(_vm.leadTypeFilter, function(filter) {
                            return _c(
                              "el-radio-button",
                              { key: filter.key, attrs: { label: filter.key } },
                              [
                                _vm._v(" " + _vm._s(filter.label) + " "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "badge",
                                    class: { "badge--active": filter.badge }
                                  },
                                  [_vm._v(" " + _vm._s(filter.badge) + " ")]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          on: { change: _vm.doFilter },
                          model: {
                            value: _vm.filters.answering_status,
                            callback: function($$v) {
                              _vm.$set(_vm.filters, "answering_status", $$v)
                            },
                            expression: "filters.answering_status"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: null } }, [
                            _vm._v("All")
                          ]),
                          _vm._l(_vm.answeringStatusFilters, function(filter) {
                            return _c(
                              "el-radio-button",
                              { key: filter.key, attrs: { label: filter.key } },
                              [
                                _vm._v(" " + _vm._s(filter.label) + " "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "badge",
                                    class: { "badge--active": filter.badge }
                                  },
                                  [_vm._v(" " + _vm._s(filter.badge) + " ")]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          on: { change: _vm.doFilter },
                          model: {
                            value: _vm.filters.client_progress,
                            callback: function($$v) {
                              _vm.$set(_vm.filters, "client_progress", $$v)
                            },
                            expression: "filters.client_progress"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: null } }, [
                            _vm._v("All")
                          ]),
                          _vm._l(_vm.clientProgressFilters, function(filter) {
                            return _c(
                              "el-radio-button",
                              { key: filter.key, attrs: { label: filter.key } },
                              [
                                _vm._v(" " + _vm._s(filter.label) + " "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "badge",
                                    class: { "badge--active": filter.badge }
                                  },
                                  [_vm._v(" " + _vm._s(filter.badge) + " ")]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm.isAdminOrSuperAdmin
                        ? _c(
                            "el-radio-group",
                            {
                              attrs: { size: "mini" },
                              on: { change: _vm.doFilter },
                              model: {
                                value: _vm.filters.source,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters, "source", $$v)
                                },
                                expression: "filters.source"
                              }
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: null } },
                                [_vm._v("All")]
                              ),
                              _vm._l(_vm.sourceFilters, function(filter) {
                                return _c(
                                  "el-radio-button",
                                  {
                                    key: filter.key,
                                    attrs: { label: filter.key }
                                  },
                                  [
                                    _vm._v(" " + _vm._s(filter.label) + " "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "badge",
                                        class: { "badge--active": filter.badge }
                                      },
                                      [_vm._v(" " + _vm._s(filter.badge) + " ")]
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isExtraFiltersHidden && !_vm.isExtraFilersCollapsed
                ? _c(
                    "div",
                    { staticClass: "radio-buttons-wrapper" },
                    _vm._l(_vm.singleFilters, function(filter) {
                      return _c(
                        "el-checkbox",
                        {
                          key: filter.key,
                          attrs: {
                            "false-label": null,
                            "true-label": 1,
                            border: "",
                            size: "mini"
                          },
                          on: { change: _vm.doFilter },
                          model: {
                            value: _vm.filters[filter.key],
                            callback: function($$v) {
                              _vm.$set(_vm.filters, filter.key, $$v)
                            },
                            expression: "filters[filter.key]"
                          }
                        },
                        [
                          _vm._v(" " + _vm._s(filter.label) + " "),
                          _c(
                            "div",
                            {
                              staticClass: "badge",
                              class: { "badge--active": filter.badge }
                            },
                            [_vm._v(" " + _vm._s(filter.badge) + " ")]
                          )
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }