var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-title-group" },
        [
          _c("h1", [_vm._v("Leads Handlers Access")]),
          _c(
            "el-button",
            {
              attrs: { type: "accent", icon: "el-icon-plus" },
              on: { click: _vm.openAddEditDialog }
            },
            [_vm._v(" Create New Lead Handler ")]
          )
        ],
        1
      ),
      _c(
        "MainPanel",
        [
          _c("div", { staticClass: "table-total" }, [
            _vm._v(
              " Total Items: " +
                _vm._s((_vm.leadsHandlers && _vm.leadsHandlers.length) || "-") +
                " "
            )
          ]),
          _c("AdminAccessList", {
            attrs: { loading: _vm.loading, admins: _vm.leadsHandlers },
            on: { edit: _vm.editItem, delete: _vm.deleteItem }
          })
        ],
        1
      ),
      _c("AddEditLeadsHandlerDialog", {
        attrs: {
          visible: _vm.addEditDialogVisible,
          "user-id": _vm.activeUser && _vm.activeUser.id
        },
        on: {
          "update:visible": function($event) {
            _vm.addEditDialogVisible = $event
          },
          close: _vm.closeAddEditDialog
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }