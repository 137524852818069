<template>
  <div>
    <div class="page-title-group">
      <h1>Admin Access</h1>

      <el-button
          type="accent"
          icon="el-icon-plus"
          @click="openAddEditDialog"
      >
        Create New Admin
      </el-button>
    </div>

    <MainPanel>
      <div class="table-total">
        Total Items: {{ admins && admins.length || '-' }}
      </div>

      <AdminAccessList
          :loading="loading"
          :admins="admins"
          @edit="editItem"
          @delete="deleteItem"
      />
    </MainPanel>

    <AddEditAdminDialog
        :visible.sync="addEditDialogVisible"
        :activeAdminId="activeAdmin && activeAdmin.id"
        @close="closeAddEditDialog"
    />

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AddEditAdminDialog from '@/components/admin-access/AddEditAdminDialog'
import AdminAccessList from '@/components/admin-access/AdminAccessList'
import MainPanel from '@/components/global/MainPanel'

export default {
  name: 'AdminAccessPage',
  metaInfo: {
    title: 'Admin Access'
  },
  components: {
    MainPanel,
    AdminAccessList,
    AddEditAdminDialog
  },
  data() {
    return {
      loading: false,
      addEditDialogVisible: false,
      activeAdmin: null
    }
  },
  computed: {
    ...mapState('admin', {
      admins: state => state.admins
    })
  },
  mounted() {
    this.getAdmins()
  },
  methods: {
    ...mapActions('admin', [
      'fetchAdmins',
      'deleteAdmin',
    ]),
    openAddEditDialog() {
      this.addEditDialogVisible = true
    },
    closeAddEditDialog() {
      this.addEditDialogVisible = false
      this.activeAdmin = null
    },
    editItem(admin) {
      this.activeAdmin = admin
      this.openAddEditDialog()
    },
    async deleteItem(admin) {
      await this.deleteAdmin(admin.id);
    },
    async getAdmins() {
      this.loading = true
      await this.fetchAdmins(false)
      this.loading = false
    }
  }
}
</script>
