import api from '@/utils/api'
import router from '@/router'
import { Message } from 'element-ui'
import * as Sentry from "@sentry/browser"

export default {
  state: () => ({
    user: null
  }),
  getters: {
    isGuest: state => {
      return state.user === null
    },
    isAdminOrSuperAdmin: state => {
      return state.user?.isAdmin || state.user?.isSuperAdmin
    },
    isSuperAdmin: state => {
      return state.user?.isSuperAdmin
    },
    showLeadHttpColumns: state => {
      return state.user.permissions.show_lead_http_columns;
    },
    hiddenColumns: state => {
      const arr = state.user?.hidden_columns && state.user?.hidden_columns[0]?.hidden_columns || []
      const obj = {}
      arr.forEach(i => {
        obj[i] = true
      })
      return obj
    },
    isColumnVisible: (state, getters) => {
      return val => {
        return !getters.hiddenColumns[val]
      }
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = user
      if(user) {
        Sentry.setUser({ id: user.id, username: user.username })
      }
    },
    addInfoToUser (state, user) {
      state.user = { ...state.user, ...user }
    },
    toggleColumnVisibility (state, key) {
      const hiddenColumns = state.user?.hidden_columns
      if (!hiddenColumns?.[0]) {
        state.user.hidden_columns[0] = { hidden_columns: [] }
      }

      if (hiddenColumns?.[0].hidden_columns?.includes(key)) {
        hiddenColumns[0].hidden_columns = hiddenColumns[0].hidden_columns.filter(x => x !== key)
      } else {
        hiddenColumns[0].hidden_columns.push(key)
      }
    }
  },
  actions: {
    async loadUser ({ commit, dispatch }) {
      try {
        const res = await api.http.get('user/me')
        commit('setUser', res?.data?.data)
      } catch {
        if (router.currentRoute.name !== 'Login') {
          dispatch('logout')
        }
      }
    },

    logout ({ commit }) {
      api.clearToken()
      commit('setUser', null)
      if (router.currentRoute.name !== 'Login') {
        router.push({ name: 'Login' })
      }
    },

    async login ({ dispatch }, form) {
      const result = await api.http.post('user/login', { ...form })
      if (result?.data?.error) {
        return result?.data
      } else {
        await api.setToken(result?.data?.token)
        await dispatch('loadUser')
        router.push({ name: 'Home' })
      }
    },

    async authByToken (_, token) {
      const { data } = await api.http.post('user/authByToken', { token })
      return data
    },

    async getUser ({ commit }) {
      const res = await api.http.get('user/getByToken')
      commit('addInfoToUser', res?.data)
      return res?.data
    },

    async updateProfile (_, form) {
      await api.http.put('user', form)
      Message.success('Profile was updated successfully')
    },

    async saveColumns (_, params) {
      await api.http.post('user/saveColumns', null, { params })
    }
  },
  namespaced: true
}
