<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
  >
    <el-dialog
        :visible="visible"
        :before-close="handleClose"
        :title="isEdit ? `Update Admin ${activeAdmin && activeAdmin.admin.username} ` : 'Create Admin'"
        width="94%"
        top="2rem"
    >
      <div class="row mb-2">
        <div v-if="!isEdit" class="col-md-4">
          <el-form-item label="Username" prop="username">
            <el-input
                v-model="form.username"
            />
          </el-form-item>
        </div>

        <div v-if="!isEdit" class="col-md-4">
          <el-form-item label="Email" prop="email">
            <el-input
                v-model="form.email"
            />
          </el-form-item>
        </div>

        <div class="col-md-4">
          <el-form-item
              label="Password"
              prop="password"
              :rules="isEdit ? rules.passwordForEdit : rules.passwordForCreate"
          >
            <el-input
                v-model="form.password"
            />
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5">
          <div class="column-heading">
            Pages Access
          </div>

          <PagesAccess
              :checkedPagesProp="form.pages"
              :pages-list="pagesList"
              @change="p =>{form.pages = p}"
          />
        </div>

        <div class="col-md-7">
          <div class="column-heading">
            Campaigns Access
          </div>

          <CampaignsAccess
              :checkedCampaignsProp="form.campaigns"
              @change="c =>{form.campaigns = c}"
          />
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button v-if="isEdit" @click="onMoveToLeadsHandlers">Move to Leads Handlers</el-button>
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import PagesAccess from '@/components/admin-access/PagesAccess'
import CampaignsAccess from '@/components/admin-access/CampaignsAccess'
import modalMixin from '@/mixins/modalMixin'

const defaultFormValues = {
  id: null,
  username: null,
  email: null,
  password: null,
  pages: {},
  campaigns: {}
}

export default {
  name: 'AddEditAdminDialog',
  mixins: [modalMixin],
  components: { CampaignsAccess, PagesAccess },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    activeAdminId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      form: { ...defaultFormValues },
      activeAdmin: null,
      rules: {
        username: [
          { required: true, message: 'Please enter username', trigger: 'change' }
        ],
        email: [
          { required: true, message: 'Please enter email', trigger: 'change' }
        ],
        passwordForEdit: [
          { min: 8, message: 'The password must be at least 8 character', trigger: 'blur' },
          { required: false, trigger: 'change' }
        ],
        passwordForCreate: [
          { min: 8, message: 'The password must be at least 8 character', trigger: 'blur' },
          { required: true, message: 'Please enter password', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    isEdit() {
      return !!(this.activeAdminId)
    },
    ...mapState('admin', [
      'pagesList',
    ])
  },
  watch: {
    async activeAdminId() {
      if (this.isEdit) {
        this.activeAdmin = await this.fetchAdminById(this.activeAdminId)
        this.fillForm()
      }
    }
  },
  async created() {
    await this.fetchCampaignsWithTeams()
    await this.fetchPagesList()
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchCampaignsWithTeams',
    ]),
    ...mapActions('admin', [
      'updateAdmin',
      'createAdmin',
      'fetchAdminById',
      'moveToLeadsHandlers',
      'fetchPagesList',
    ]),
    fillForm() {
      this.form.id = this.activeAdmin?.admin?.id
      this.form.pages = this.activeAdmin?.permissions
      this.form.campaigns = this.activeAdmin?.campaignsPermissions
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.isEdit) {
            if (!this.form.password) {
              delete this.form.password
            }
            await this.updateAdmin(this.form)
          } else {
            await this.createAdmin(this.form)
          }
          this.$emit('refetch-data')
          this.closeModal()
        }
      })
    },
    async onMoveToLeadsHandlers() {
      try {
        await this.$confirm('Are you sure to change role for this users. Some permissions might be lost', '', { type: 'warning' })
        await this.moveToLeadsHandlers(this.form.id)
        this.closeModal()
      } catch (_) {}
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal)
    },
    closeModal() {
      this.resetForm()
      this.$emit('close')
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 800px;
  margin-bottom: 0;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}

::v-deep .checkbox-list {
  max-height: calc(100vh - 400px);
  overflow: auto;
}

::v-deep .el-checkbox {
  display: block;
}

.column-heading {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: bold;
}
</style>
