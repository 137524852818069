<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="form"
    label-position="top"
  >
    <el-dialog
      :visible="visible"
      :before-close="handleClose"
      :title="isEdit ? `Update User ${activeUser && activeUser.user.username} ` : 'Create User'"
      width="94%"
      top="2rem"
    >
      <div class="row mb-2">
        <div v-if="!isEdit" class="col-md-4">
          <el-form-item label="Username" prop="username">
            <el-input
              v-model="form.username"
            />
          </el-form-item>
        </div>

        <div v-if="!isEdit" class="col-md-4">
          <el-form-item label="Email" prop="email">
            <el-input
              v-model="form.email"
            />
          </el-form-item>
        </div>

        <div class="col-md-4">
          <el-form-item
            label="Password"
            prop="password"
            :rules="isEdit ? rules.passwordForEdit : rules.passwordForCreate"
          >
            <el-input
              v-model="form.password"
            />
          </el-form-item>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5">
          <div class="column-heading">
            Pages Access
          </div>

          <PagesAccess
            :checkedPagesProp="form.pages"
            :pages-list="pagesList"
            @change="p =>{form.pages = p}"
          />
        </div>

        <div class="col-md-7">
          <div class="column-heading">
            Campaigns Access
          </div>

          <CampaignsAccess
            :checkedCampaignsProp="form.campaigns"
            @change="c =>{form.campaigns = c}"
            :disabled="!isCampaignsEditable"
          />
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button v-if="isEdit" @click="onMoveToAdmins">Move to Admins</el-button>
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import {mapActions, mapGetters, mapState} from "vuex";
import CampaignsAccess from "@/components/admin-access/CampaignsAccess.vue";
import PagesAccess from "@/components/admin-access/PagesAccess.vue";

const defaultFormValues = {
  id: null,
  username: null,
  email: null,
  password: null,
  pages: {},
  campaigns: {}
}

export default {
  name: 'AddEditLeadsHandlerDialog',
  components: {PagesAccess, CampaignsAccess},
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    userId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      form: { ...defaultFormValues },
      activeUser: null,
      rules: {
        username: [
          { required: true, message: 'Please enter username', trigger: 'change' }
        ],
        email: [
          { required: true, message: 'Please enter email', trigger: 'change' }
        ],
        passwordForEdit: [
          { min: 8, message: 'The password must be at least 8 character', trigger: 'blur' },
          { required: false, trigger: 'change' }
        ],
        passwordForCreate: [
          { min: 8, message: 'The password must be at least 8 character', trigger: 'blur' },
          { required: true, message: 'Please enter password', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    isEdit() {
      return !!(this.userId)
    },
    ...mapState('leadsHandlers', [
      'pagesList',
    ]),
    ...mapGetters('auth', [
      'isSuperAdmin',
    ]),
    ...mapState('campaigns', {
      campaigns: 'allCampaigns'
    }),
    isCampaignsEditable() {
      if (this.isSuperAdmin) {
        return true;
      }

      let selectedCampaignsCount = 0
      for (const key in (this.activeUser?.campaignsPermissions || {})) {
        if (this.activeUser?.campaignsPermissions[key] === 1) {
          selectedCampaignsCount++;
        }
      }

      if (this.campaigns?.length !== selectedCampaignsCount) {
        return true;
      }

      return false;
    },
  },
  watch: {
    async userId() {
      if (this.isEdit) {
        this.activeUser = await this.fetchLeadsHandlerById(this.userId);
        this.fillForm();
      }
    }
  },
  async created() {
    await this.fetchCampaignsWithTeams()
    await this.fetchPagesList()
  },
  methods: {
    ...mapActions('campaigns', [
      'fetchCampaignsWithTeams',
    ]),
    ...mapActions('leadsHandlers', [
      'fetchLeadsHandlerById',
      'createLeadsHandler',
      'updateLeadsHandler',
      'moveToAdmins',
      'fetchPagesList'
    ]),
    fillForm() {
      this.form.id = this.activeUser?.user?.id
      this.form.pages = this.activeUser?.permissions
      this.form.campaigns = this.activeUser?.campaignsPermissions
    },
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.isEdit) {
            if (!this.form.password) {
              delete this.form.password
            }
            await this.updateLeadsHandler(this.form)
          } else {
            await this.createLeadsHandler(this.form)
          }
          this.$emit('refetch-data')
          this.closeModal()
        }
      })
    },
    async onMoveToAdmins() {
      try {
        await this.$confirm('Are you sure to change role for this user? He might get extra permissions', '', {type: 'warning'})
        await this.moveToAdmins(this.form.id)
        this.closeModal()
      } catch(_) {}
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal)
    },
    closeModal() {
      this.resetForm()
      this.$emit('close')
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 800px;
  margin-bottom: 0;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}

::v-deep .checkbox-list {
  max-height: calc(100vh - 400px);
  overflow: auto;
}

::v-deep .el-checkbox {
  display: block;
}

.column-heading {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: bold;
}
</style>
