<template>
  <div>
    <div class="page-title-group">
      <h1>Leads Handlers Access</h1>

      <el-button
        type="accent"
        icon="el-icon-plus"
        @click="openAddEditDialog"
      >
        Create New Lead Handler
      </el-button>
    </div>

    <MainPanel>
      <div class="table-total">
        Total Items: {{ leadsHandlers && leadsHandlers.length || '-' }}
      </div>

      <AdminAccessList
        :loading="loading"
        :admins="leadsHandlers"
        @edit="editItem"
        @delete="deleteItem"
      />
    </MainPanel>

    <AddEditLeadsHandlerDialog
      :visible.sync="addEditDialogVisible"
      :user-id="activeUser && activeUser.id"
      @close="closeAddEditDialog"
    />
  </div>


</template>

<script>
import MainPanel from "@/components/global/MainPanel.vue";
import {mapActions, mapState} from "vuex";
import AdminAccessList from "@/components/admin-access/AdminAccessList.vue";
import AddEditLeadsHandlerDialog from "@/components/leads-handlers-access/AddEditLeadsHandlerDialog.vue";

export default {
  name: 'LeadHandlersAccessPage',
  components: {AddEditLeadsHandlerDialog, AdminAccessList, MainPanel},
  metaInfo: {
    title: 'Leads Handlers Access'
  },
  data() {
    return {
      loading: false,
      addEditDialogVisible: false,
      activeUser: null
    }
  },
  computed: {
    ...mapState('leadsHandlers', [
      'leadsHandlers'
    ]),
  },
  mounted() {
    this.getLeadsHandlers();
  },
  methods: {
    ...mapActions('leadsHandlers', [
      'fetchLeadsHandlers',
      'deleteLeadsHandler',
    ]),
    openAddEditDialog() {
      this.addEditDialogVisible = true
    },
    closeAddEditDialog() {
      this.addEditDialogVisible = false
      this.activeUser = null
    },
    editItem(user) {
      this.activeUser = user
      this.openAddEditDialog()
    },
    deleteItem(user) {
      this.deleteLeadsHandler(user.id);
    },
    async getLeadsHandlers() {
      this.loading = true;
      await this.fetchLeadsHandlers(false);
      this.loading = false;
    },
  },
}
</script>

<style scoped lang="scss">

</style>
