<template>
  <div v-loading="loading">
    <div v-if="(admins) || loading" class="table-wrapper">
      <table class="table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Assigned Campaigns</th>
          <th class="fixed-right-column text-center">Actions</th>
        </tr>
        </thead>

        <tbody v-if="admins">
        <tr v-for="admin in admins" :key="admin.id">
          <td>
            {{ admin.username }}
          </td>
          <td>
            {{ admin.email }}
          </td>
          <td>
            {{ admin.campaigns_count }}
          </td>
          <td class="fixed-right-column">
            <el-button-group>
              <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  title="Edit"
                  @click="onEdit(admin)"
              />
              <el-button
                  type="danger"
                  size="mini"
                  title="Delete"
                  icon="el-icon-delete"
                  @click="onDelete(admin)"
              />
            </el-button-group>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <EmptyList v-else/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import EmptyList from '@/components/global/EmptyList'

export default {
  name: 'AdminAccessList',
  components: {
    EmptyList
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    admins: {
      type: Array,
      default: null
    }
  },
  methods: {
    ...mapActions('admin', [
      'deleteAdmin'
    ]),
    onEdit(admin) {
      this.$emit('edit', admin)
    },
    async onDelete(admin) {
      try {
        const res = await this.$confirm('Are you sure to remove this admin?')
        if (res) {
          this.$emit('delete', admin);
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.table-wrapper {
  max-height: calc(100vh - 200px) !important;
}

</style>
