import api from "@/utils/api";
import {Message} from "element-ui";

export default {
  state: () => ({
    leadsHandlers: null,
    pagesList: null,
  }),
  mutations: {
    setLeadsHandlers(state, payload) {
      state.leadsHandlers = Object.freeze(Object.values(payload));
    },

    setPagesList(state, payload) {
      state.pagesList = payload
    }
  },
  getters: {},
  actions: {
    async fetchLeadsHandlers({ commit, state }, cache = true) {
      if (cache && state.leadsHandlers) return
      const result = await api.http.get('leads-handlers');
      commit('setLeadsHandlers', result?.data?.users);
    },
    async fetchLeadsHandlerById(context, leadHandlerId) {
      const res = await api.http.get(`leads-handlers/${leadHandlerId}`);
      return res?.data;
    },

    async fetchPagesList({ commit }) {
      const result = await api.http.get('leads-handlers/pages-list')
      commit('setPagesList', result?.data?.pages)
    },

    async createLeadsHandler({ dispatch }, form) {
      await api.http.post('leads-handlers', form)
      dispatch('fetchLeadsHandlers', false)
      Message.success('Success')
    },

    async updateLeadsHandler({ dispatch }, form) {
      await api.http.patch(`leads-handlers/${form.id}`, form)
      dispatch('fetchLeadsHandlers', false)
      Message.success('Success')
    },

    async deleteLeadsHandler({ dispatch }, id) {
      await api.http.delete(`leads-handlers/${id}`)
      dispatch('fetchLeadsHandlers', false)
      Message.success('Success')
    },

    async moveToAdmins({ dispatch }, id) {
      await api.http.patch(`leads-handlers/${id}/move-to-admins`)
      dispatch('fetchLeadsHandlers', false)
      Message.success('Success')
    },
  },
  namespaced: true
}
