import VueRouter from 'vue-router'
import Dashboard from '../pages/Dashboard'
import CampaignsPage from '../pages/CampaignsPage'
import ActivityLogPage from '@/pages/ActivityLogPage'
import AdminAccessPage from '@/pages/AdminAccessPage'
import IPPage from '@/pages/IPsPage'
import NetworkCategoriesPage from '@/pages/NetworkCategoriesPage'
import ClientSitesPage from '@/pages/ClientSitesPage'
import CampaignCategoriesPage from '@/pages/CampaignCategoriesPage'
import OutgoingLinksPage from '@/pages/OutgoingLinksPage'
import NetworkSitesPage from '@/pages/NetworkSitesPage'
import RedirectDomainsPage from '@/pages/RedirectDomainsPage'
import ClientsPage from '@/pages/ClientsPage'
import ChangelogPage from '@/pages/ChangelogPage'
import GMBsPage from '@/pages/GMBsPage'
import GMBsMapPage from '@/pages/GMBsMapPage'
import CpanelPage from '@/pages/CpanelPage'
import LoginPage from '@/pages/LoginPage'
import GMBManagerPage from '@/pages/GMBManagerPage'
import LeadsPage from '@/pages/LeadsPage'
import LeadsRelatedPage from '@/pages/LeadsRelatedPage'
import LeadsChartsPage from '@/pages/LeadsChartsPage'
import LeadsTablePage from '@/pages/LeadsTablePage'

import api from '@/utils/api'
import store from '@/store/index'
import RateLeadPage from "@/pages/RateLeadPage.vue";
import LeadsHandlersAccessPage from "@/pages/LeadsHandlersAccessPage.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      layout: 'auth-layout',
    },
  },
  {
    path: '/rate',
    name: 'RateLead',
    component: RateLeadPage,
    meta: {
      layout: 'simple-layout'
    },
  },
  {
    path: '/rate/not-lead',
    name: 'RateNotLead',
    component: RateLeadPage,
    meta: {
      layout: 'simple-layout'
    },
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: CampaignsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/activity-log',
    name: 'ActivityLog',
    component: ActivityLogPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/pages',
    name: 'AdminAccess',
    component: AdminAccessPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/leads-handlers',
    name: 'LeadsHandlersAccessPage',
    component: LeadsHandlersAccessPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ips',
    name: 'Ips',
    component: IPPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/categories',
    name: 'Categories',
    component: NetworkCategoriesPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/client-sites',
    name: 'ClientSites',
    component: ClientSitesPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign-categories',
    name: 'CampaignCategories',
    component: CampaignCategoriesPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/network-sites/outgoing-links',
    name: 'OutgoingLinks',
    component: OutgoingLinksPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/network-sites',
    name: 'NetworkSites',
    component: NetworkSitesPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/redirect-domains',
    name: 'RedirectDomains',
    component: RedirectDomainsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients',
    name: 'Clients',
    component: ClientsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigns-changelog',
    name: 'CampaignsChangelog',
    component: ChangelogPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign-gmbs',
    name: 'CampaignGmbs',
    component: GMBsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign-gmbs/map',
    name: 'CampaignGmbsMap',
    component: GMBsMapPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cpanel',
    name: 'Cpanel',
    component: CpanelPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/leads',
    name: 'Leads',
    component: LeadsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/leads/archive',
    name: 'LeadsArchive',
    component: LeadsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/leads/chart',
    name: 'LeadsChart',
    component: LeadsChartsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/leads/table',
    name: 'LeadsTable',
    component: LeadsTablePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/leads-related',
    name: 'LeadsRelated',
    component: LeadsRelatedPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/gmb-manager',
    name: 'GMBManager',
    component: GMBManagerPage,
    meta: {
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next)=>{
  if (to?.query?.token) {
    // const res = await store.dispatch('auth/authByToken', to.query.token)
    await api.setToken(to?.query?.token)
    await router.push(to.path)
    document.location.reload()
    await store.dispatch('auth/loadUser')
    next()
  } else {
    next()
  }
})

export default router
